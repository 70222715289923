<template>
  <div class="page" v-loading="loading">
    <div class="dkzc-box" v-if="detail">
      <div class="left">
        <!-- 标题 -->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/found' }">发现</el-breadcrumb-item>
          <el-breadcrumb-item>正文</el-breadcrumb-item>
        </el-breadcrumb>
        <onshare></onshare>
        <!-- 个人信息 -->
        <div class="personage">
          <img :src="imgUrl" style="cursor: pointer" @click="goPersonal" />
          <div class="personage-right">
            <div class="one">
              <span class="title">{{ detail.info.doctor.name }}</span>
              <span class="position">{{ detail.info.doctor.title_name }}</span>
              <template v-if="detail.info.doctor.user_id != userInfo.id">
                <span
                  v-if="detail.info.attention"
                  @click="postDiscoverAttention()"
                  class="button2"
                  >已关注</span
                >
                <span @click="postDiscoverAttention()" v-else class="button"
                  >+关注</span
                >
              </template>
            </div>
            <div class="two">
              <span class="site">{{ detail.info.doctor.company }}</span>
              <span>{{ detail.info.doctor.department_name }}</span>
            </div>
          </div>
        </div>
        <!-- 简介 -->
        <div class="content ql-editor">
          <!-- 标题 -->
          <div class="content-title" v-if="detail.info.title">
            {{ detail.info.title }}
          </div>
          <!-- 副标题 -->
          <div
            v-if="
              detail.info.type != 2 &&
              detail.info.type != 4 &&
              detail.info.type != 1
            "
            class="subTitle"
            style="white-space: pre-wrap; color: #8f8f8f"
            v-html="detail.info.content"
          ></div>

          <div
            v-if="detail.info.type == 4"
            style="white-space: pre-wrap; color: #8f8f8f"
            v-html="detail.info.content"
            class="subTitle"
          ></div>

          <div
            v-if="
              detail.info.type == 1 ||
              detail.info.type == 3 ||
              detail.info.type == 2
            "
            v-html="detail.info.content"
            class="subTitle"
          ></div>

          <el-carousel
            v-if="detail.info.images.length"
            trigger="click"
            height="300px"
            style="margin-bottom: 10px"
          >
            <el-carousel-item v-for="item in detail.info.images" :key="item">
              <el-image
                style="width: 100%; height: 100%"
                :src="item"
                fit="contain"
                :preview-src-list="detail.info.images"
              >
              </el-image>
            </el-carousel-item>
          </el-carousel>

          <!-- <div class="content-imgone" v-if="detail.info.images.length == 1">
            <img
              @click="imgView(detail.info.images[0])"
              :src="detail.info.images[0]"
              alt=""
            />
          </div> -->

          <!-- <div class="content-imgtwo" v-if="detail.info.images.length > 1">
            <img
              @click="imgView(it)"
              :src="it"
              v-for="(it, i) in detail.info.images"
              :key="i"
              alt=""
            />
          </div> -->

          <!-- 词条 -->
          <span
            v-if="detail.info.disease"
            class="subTitle"
            style="color: #8f8f8f"
            >疾病名称：{{ detail.info.disease }}</span
          >
          <span
            v-if="detail.info.search"
            class="subTitle"
            style="color: #8f8f8f"
            >搜索词：{{ detail.info.search }}</span
          >

          <!-- 附件 -->
          <div v-if="detail.info.file_path" class="enclosure">
            <span
              class="item"
              v-for="(item, index) in detail.info.file_path"
              :key="index"
            >
              <!-- <i class="el-icon-document"></i>附件{{ index + 1
              }}{{ item.substring(item.lastIndexOf("."), item.length) }} -->
              <a
                v-if="
                  item.substring(item.lastIndexOf('.'), item.length) == '.docx'
                "
                target="_blank"
                :href="item"
                download
              >
                <img src="@image/word.png" alt="" />
              </a>
              <a
                v-if="
                  item.substring(item.lastIndexOf('.'), item.length) == '.pdf'
                "
                target="_blank"
                :href="item"
                download
              >
                <img src="@image/pdf.png" alt="" />
              </a>
            </span>
          </div>

          <!-- 时间 -->
          <span class="contentTime">{{
            detail.info.create_time | formaTime
          }}</span>
        </div>
        <!-- 点赞收藏图标区 -->
        <div class="icons">
          <div class="favoritesNumber">
            <i
              @click="collectionFn(detail.info.collect_status)"
              class="iconfont shoucang"
              :class="{ shoucanghover: detail.info.collect_status }"
            ></i>
            <span>{{ detail.info.collect_num || 0 }}</span>
          </div>
          <div class="like">
            <i
              @click="articlePraiseFn"
              class="iconfont zan1"
              :class="{ iconzan2: !detail.info.praise_status }"
            ></i>
            <span>{{ detail.info.praise_num || 0 }}</span>
          </div>

          <div
            v-if="detail.info.user_id != userInfo.id"
            class="report"
            @click="chengReport(id, 1, detail.info.user_id)"
          >
            <img src="@image/report.png" alt="" />
            举报
          </div>
          <span class="share is_share_show">分享：</span>
          <sharee class="is_share_show" :config="config"></sharee>
        </div>
        <!-- 评论数 -->
        <commentNum
          :commentNumber="detail.info.comment_num"
          :likeCount="detail.likeCount"
          :shareCount="detail.sharecount"
          :id="id"
        ></commentNum>
        <comment
          width="840"
          @refresh="findDetail"
          :discover_id="detail.info.id"
          :comments="detail.comment"
          :release_id="detail.info.doctor.user_id"
          @delete_comment="delete_comment"
        >
        </comment>
      </div>
      <!-- 右侧信息区 -->
      <div class="right-group" v-if="detail.recommend.length">
        <div
          class="group"
          v-for="(item, index) in detail.recommend"
          :key="index"
          @click="goDetail(item)"
        >
          <div class="group-left">
            <!-- <span class="Play">12:11</span> -->
            <img
              class="information"
              v-if="item.video_img || item.images.length"
              :src="item.video_img || item.images[0]"
            />
          </div>
          <div class="right">
            <span class="title">{{ item.title }}</span>
            <div class="site">
              <!-- <span>{{ item.content }}</span> -->

              <div style="line-height: 18px" v-html="item.content"></div>
              <!-- <span class="name">肖喜荣</span> -->
            </div>
            <div class="time">
              <span style="color: #8f8f8f">{{ item.browse_num }}次观看</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right-group" v-else>
        <div class="null-box">
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="imgViewUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import comment from "@components/common/comment";
import commentNum from "@components/common/commentNum";
import onshare from "@components/share/onShare";
import sharee from "@components/share/share";
import { mapState } from "vuex";
export default {
  components: {
    comment,
    commentNum,
    onshare,
    sharee,
  },
  data() {
    return {
      id: 0,
      user_id: "",
      detail: "",
      islod: true,
      dialogVisible: false,
      imgViewUrl: "", // 预览图片
      loading: true,
      config: {
        title: "发现详情",
        url: window.location.href,
        sites: ["wechat", "qq", "weibo"],
        wechatQrcodeTitle: "微信扫一扫：分享",
        wechatQrcodeHelper:
          "<p>扫一扫</p><p>二维码便可将本文分享至朋友圈。</p>",
      },
    };
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),
    // 默认图片
    imgUrl() {
      return this.detail.info.doctor.photo
        ? this.detail.info.doctor.photo
        : require("@image/default.png");
    },
  },
  watch: {
    $route(route) {
      this.id = route.query.id;
      this.findDetail();
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.findDetail();
  },
  methods: {
    goPersonal() {
      let url = "/found-personal?id=" + this.detail.info.doctor.user_id;
      this.xin.goUrl(url);
    },

    goDetail(item) {
      if (item.type == 3) {
        // this.xin.goUrl("/found-details-videoDetails?id=" + item.id);
        if (this.id != item.id) {
          this.xin.goUrl("/found-details-videoDetails?id=" + item.id);
        } else {
          this.$message({
            message: "您正在看此条数据 ",
            type: "error",
          });
        }
      } else {
        // this.xin.goUrl("/found-details-details?id=" + item.id);
        if (this.id != item.id) {
          this.xin.goUrl("/found-details-details?id=" + item.id);
        } else {
          this.$message({
            message: "您正在看此条数据",
            type: "error",
          });
        }
      }
    },
    // 图片预览
    imgView(url) {
      this.dialogVisible = true;
      this.imgViewUrl = url;
    },
    // 发现详情
    async findDetail() {
      // this.xin.isLoadding(true);
      let res = await this.$findApi.findDetail({
        discover_id: this.id,
      });
      this.loading = false;
      if (res.code == 200) {
        let data = res.data || [];
        if (data.info && data.info.images) {
          data.info.images = JSON.parse(data.info.images);
        } else {
          data.info.images = [];
        }

        if (data.recommend.length) {
          for (let i in data.recommend) {
            data.recommend[i].images
              ? (data.recommend[i].images = JSON.parse(
                  data.recommend[i].images
                ))
              : (data.recommend[i].images = []);
          }
        }
        this.detail = data;
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
      }
      // this.xin.isLoadding();
    },
    // 关注
    async postDiscoverAttention() {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }

      let item = this.detail.info.doctor;
      // 防止多次发送请求
      if (this.islod) {
        this.islod = false;
        let res = await this.$userApi.postDiscoverAttention({
          attention_id: item.user_id,
        });
        this.islod = true;
        if (res.code == 200) {
          if (this.detail.info.attention) {
            this.$message({
              message: "已取消关注",
              type: "success",
            });
            this.detail.info.attention = false;
          } else {
            this.$message({
              message: "关注成功",
              type: "success",
            });
            this.detail.info.attention = true;
          }
        } else {
          this.$message.error(res.message);
        }
      }
    },
    // 收藏
    async collectionFn(type) {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }

      let data = {
        discover_id: this.$route.query.id,
      };
      if (this.islod) {
        this.islod = false;
        let res = await this.$findApi.findCollection(data);
        this.islod = true;
        if (res.code == 200) {
          this.detail.info.collect_status = !type;
          if (type) {
            this.detail.info.collect_num -= 1;
            this.$message({
              message: "已取消收藏",
              type: "success",
            });
          } else {
            this.detail.info.collect_num += 1;
            this.$message({
              message: "收藏成功",
              type: "success",
            });
          }
        } else {
          this.$message({
            message: data.message,
            type: "error",
          });
        }
      }
    },
    // 文章点赞
    async articlePraiseFn() {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }
      let data = {
        like_id: this.$route.query.id,
        type: 1,
      };
      if (this.islod) {
        this.islod = false;
        let res = await this.$findApi.findCommentLike(data);
        this.islod = true;
        if (res.code == 200) {
          if (this.detail.info.praise_status) {
            this.detail.info.praise_num -= 1;
            this.$message({
              message: "已取消点赞",
              type: "success",
            });
            this.findDetail();
          } else {
            this.detail.info.praise_num += 1;
            this.$message({
              message: "点赞成功",
              type: "success",
            });
            this.findDetail();
          }
          this.detail.info.praise_status = !this.detail.info.praise_status;
        } else {
          this.$message.error(res.message);
        }
      }
    },

    // 举报
    chengReport(discover_id, type, user_id) {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      } else {
        this.bus.$emit("report", discover_id, type, user_id);
      }
    },

    // 删除评论
    async delete_comment(id) {
      let { message, code } = await this.$findApi.findDelete({
        id,
      });
      if (code == 200) {
        this.detail.comment = this.detail.comment.filter((item) => {
          item.reply = item.reply.filter((it) => {
            return it.id != id;
          });
          return item.id != id;
        });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      } else {
        this.$message({
          type: "error",
          message,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

/deep/ .ql-editor {
  width: 100%;
  padding: 0;

  .el-carousel__indicators {
    display: none;

    li {
      padding-left: 5px;
      list-style: none;

      &::before {
        display: none;
      }
    }
  }
}

.iconfont {
  font-size: 28px;
  cursor: pointer;
}

.iconzan2 {
  color: #e60012;
}

video,
textarea {
  &:focus {
    outline: none;
  }
}

.page {
  > .dkzc-box {
    display: flex;
    flex-direction: row;
    width: 1200px;
    margin: 0 auto;

    > .left {
      display: flex;
      flex-direction: column;
      margin-right: 16px;
      width: 890px;

      > .title {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
        margin-right: 10px;
      }

      > .personage {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;

        > img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          object-fit: cover;
        }

        > .personage-right {
          display: flex;
          flex-direction: column;
          margin-left: 12px;
          width: 100%;

          > .one {
            display: flex;
            flex-direction: row;
            color: #333333;
            font-size: 24px;

            > .position {
              font-size: 13px;
              color: $--themeColor;
              width: 75px;
              height: 25px;
              border: 1px solid $--themeColor;
              border-radius: 6px;
              text-align: center;
              line-height: 26px;
              margin-left: 12px;
            }

            > .button {
              cursor: pointer;
              position: absolute;
              right: 0;
              width: 56px;
              height: 25px;
              background: $--themeColor;
              border-radius: 5px;
              color: #ffffff;
              text-align: center;
              line-height: 26px;
              margin-left: 287px;
              font-size: 12px;
            }

            > .button2 {
              cursor: pointer;
              width: 66px;
              height: 25px;
              border: 1px solid #8f8f8f;
              border-radius: 5px;
              color: #8f8f8f;
              text-align: center;
              line-height: 26px;
              margin-left: auto;
              font-size: 12px;
            }
          }

          > .two {
            display: flex;
            flex-direction: row;
            color: #8f8f8f;
            font-size: 16px;
            margin-top: 9px;

            > .site {
              margin-right: 20px;
            }
          }
        }
      }

      > .content {
        display: flex;
        flex-direction: column;
        color: #333333;
        font-size: 24px;

        .content-title {
          margin-bottom: 10px;
        }

        .content-imgone {
          margin-top: 10px;

          img {
            width: 100%;
            height: 390px;
            object-fit: cover;
            border-radius: 10px;
          }
        }

        .content-imgtwo {
          display: flex;
          flex-wrap: wrap;
          padding: 0 10px;

          img {
            box-sizing: border-box;
            width: 270px;
            height: 130px;
            object-fit: cover;
            margin: 10px;
            border-radius: 10px;
          }
        }

        > .title {
          margin-bottom: 20px;
        }

        > .subTitle {
          width: 890px;
          word-break: break-all;
          line-height: 1.8;
          font-size: 18px;

          /deep/ img {
            max-width: 100% !important;
            display: block;
            margin: 0 auto;
            margin-bottom: 10px;
          }
        }

        > .contentTime {
          font-size: 14px;
          color: #8f8f8f;
          margin-top: 10px;
          padding: 10px 0 20px 0;
        }

        .enclosure {
          margin-top: 20px;

          .item {
            color: #8f8f8f;
            font-size: 16px;
            margin-bottom: 10px;

            a {
              margin-left: 10px;
              text-decoration: none;
              color: #8f8f8f;
              img {
                width: 60px;
                margin-right: 20px;
              }
            }
          }
        }
      }

      > .icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #8f8f8f;
        margin-top: 33px;
        cursor: pointer;
        font-size: 24px;

        > .favoritesNumber {
          margin-right: 40px;

          .shoucanghover {
            color: #e60012;
          }
        }

        > .like {
          .zan1 {
            color: #e60012;
          }

          .iconzan2 {
            color: #8f8f8f;
          }
        }

        .report {
          font-size: 22px;
          margin-left: 40px;

          img {
            width: 24px;
            vertical-align: bottom;
          }
        }

        > .share {
          font-size: 16px;
          margin-left: auto;
        }

        > .qq {
          margin: 0 20px;
        }
      }
    }

    > .right-group {
      display: flex;
      flex-direction: column;
      width: 294px;
      height: min-content;
      background: #f2f2f2;
      padding: 15px;
      box-sizing: border-box;
      margin-top: 30px;

      > .group {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        padding-bottom: 20px;

        &:not(:last-child) {
          border-bottom: 1px dashed #000;
        }

        &:last-child {
          margin-bottom: 0;
        }

        > .group-left {
          display: flex;
          position: relative;

          > .information {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 10px;
            margin-right: 10px;
          }

          > .Play {
            position: absolute;
            font-size: 12px;
            color: #ffffff;
            width: 40px;
            height: 18px;
            background: #000000;
            border-radius: 5px;
            text-align: center;
            line-height: 18px;
            left: 57px;
            top: 79px;
          }
        }

        > .right {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          color: #000;
          max-height: 100px;
          overflow: hidden;

          > .title {
            color: #333333;
            line-height: 20px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
          }

          > .site {
            margin-bottom: 10px;
            height: 55px;
            overflow: hidden;
            margin-top: 5px;

            span {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
            }

            > .name {
              margin-left: 8px;
            }
          }

          > .time {
            margin-top: auto;
          }
        }
      }
    }
  }
}
</style>
